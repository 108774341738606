import React, {createContext, useState, useEffect} from 'react';
import {merge} from 'lodash';
import {storeSettings} from 'src/utils/settings';
import {DEFAULT_SETTINGS, DEFAULT_SETTINGS_CONTEXT} from 'src/defs/constants';
import {TypeSettingsContext, TypeSettings} from 'src/defs/types';

export const SettingsContext = createContext<TypeSettingsContext>(DEFAULT_SETTINGS_CONTEXT);

export const SettingsConsumer = SettingsContext.Consumer;

export const SettingsProvider = ({settings, children}: {settings: TypeSettings | null; children: React.ReactChild}) => {
    const [currentSettings, setCurrentSettings] = useState<TypeSettings>(settings ?? DEFAULT_SETTINGS);

    const handleSaveSettings = (updatedSettings = {}) => {
        const mergedSettings = merge({}, currentSettings, updatedSettings);

        setCurrentSettings(mergedSettings);
        storeSettings(mergedSettings);
    };

    useEffect(() => {
        document.dir = currentSettings.direction;
    }, [currentSettings]);

    return (
        <SettingsContext.Provider
            value={{
                settings: currentSettings,
                saveSettings: handleSaveSettings,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};
