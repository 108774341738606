import React from 'react';
import {NavLink as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import {Button, ListItem, makeStyles} from '@material-ui/core';
import {Icon} from 'react-feather';

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'block',
        paddingTop: 0,
        paddingBottom: 0,
    },
    itemLeaf: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    button: {
        color: theme.palette.text.secondary,
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
    },
    buttonLeaf: {
        color: theme.palette.text.secondary,
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightRegular,
        '&.depth-0': {
            '& $title': {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
    },
    title: {
        marginRight: 'auto',
    },
    active: {
        color: theme.palette.secondary.main,
        '& $title': {
            fontWeight: theme.typography.fontWeightMedium,
        },
        '& $icon': {
            color: theme.palette.secondary.main,
        },
    },
}));

export const NavItem = ({title, href, icon, active}: {title: string; href: string; icon: Icon; active?: boolean}) => {
    const classes = useStyles();
    const NavIcon = icon;
    const activeFlag = active ? classes.active : null;

    return (
        <ListItem className={clsx(classes.itemLeaf)} disableGutters key={title}>
            <Button
                activeClassName={classes.active}
                className={clsx(classes.buttonLeaf, null, activeFlag, 'depth-0')}
                component={RouterLink}
                exact
                style={{paddingLeft: 8}}
                to={href}
            >
                <NavIcon className={classes.icon} size='20' />
                <span className={classes.title}>{title}</span>
            </Button>
        </ListItem>
    );
};
