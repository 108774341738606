import {DEFAULT_LOCALE, Locale} from 'src/defs/i18n';

// @todo refactor if translations are needed
const getLocale = () => DEFAULT_LOCALE;

export function localized<T>(
    defaultStrings: T,
    languageSpecificStrings?: {
        [key in Locale]?: Partial<T>;
    },
): T {
    // @ts-ignore this ignore isn't harmful to the generic type above
    return new Proxy(defaultStrings, {
        get: (target: T, key: string) => {
            const locale = getLocale();

            // @ts-ignore this ignore isn't harmful to the generic type above
            return languageSpecificStrings?.[locale]?.[key] ?? target[key];
        },
    });
}
