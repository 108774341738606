import {applyMiddleware, createStore, compose, StoreEnhancer} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import {createLogger} from 'redux-logger';
import {rootReducer} from 'src/store/reducers';

const loggerMiddleware = createLogger({
    collapsed: true,
});

export const configureStore = (preloadedState = {}) => {
    const middlewares = [thunkMiddleware, ...(process.env.NODE_ENV !== 'production' ? [loggerMiddleware] : [])];

    const middlewareEnhancer = composeWithDevTools(applyMiddleware(...middlewares));

    const enhancers = [middlewareEnhancer];
    const composedEnhancers: StoreEnhancer = compose(...enhancers);

    const store = createStore(rootReducer, preloadedState, composedEnhancers);

    return store;
};
