import {useUser} from 'src/hooks/useUser';
import {Role, Permission, rules} from 'src/defs/rules';

type TypePermissions = {
    checkResource: (resource: Permission) => boolean;
    isSuperAdmin: () => boolean;
    isAdmin: () => boolean;
    isClientAdmin: () => boolean;
    isAnyAdmin: () => boolean;
    isClient: () => boolean;
    isEmployee: () => boolean;
};
export const usePermissions = (): TypePermissions => {
    const {role, permissions: userPermissions} = useUser();

    const checkResource = (resource: Permission): boolean => {
        if (role === Role.SUPER_ADMIN) {
            return true;
        }

        const permissions = rules[role];

        if (!permissions) {
            return false;
        }

        const staticPermissions = [...permissions.static, ...userPermissions];

        if (staticPermissions && staticPermissions.includes(resource)) {
            return true;
        }

        return false;
    };

    return {
        checkResource,
        isSuperAdmin: () => role === Role.SUPER_ADMIN,
        isAdmin: () => [Role.SUPER_ADMIN, Role.ADMIN].includes(role),
        isClientAdmin: () => role === Role.CLIENT_ADMIN,
        isAnyAdmin: () => [Role.SUPER_ADMIN, Role.ADMIN, Role.CLIENT_ADMIN].includes(role),
        isClient: () => role === Role.CLIENT,
        isEmployee: () => role === Role.EMPLOYEE,
    };
};
