export enum Locale {
    EN_US = 'en-us',
}

export const DEFAULT_LOCALE = Locale.EN_US;

export enum Currency {
    USD = '$',
    USD_FULL = 'US$',
}

export const DEFAULT_CURRENCY = Currency.USD;
export const DATEPICKER_DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_FORMAT_UTC = "yyyy-MM-dd'T'00:00:00.000'Z'";
export const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';
export const DEFAULT_DATE_MASK = '__/__/____';
export const DEFAULT_DATE_PLACEHOLDER = 'mm/dd/yyyy';
export const DEFAULT_DATE_AND_TIME_FORMAT = 'MM/dd/yyyy hh:mm a';
