import React from 'react';
import {useLocation} from 'react-router';
import {Link as RouterLink} from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {Avatar, Box, Divider, Drawer, Hidden, Link, Typography, makeStyles} from '@material-ui/core';
import {Logo} from 'src/components/Logo';
import {useUser} from 'src/hooks/useUser';
import {NavItem} from 'src/layouts/InternalLayout/NavItem';
import {
    PieChart as PieChartIcon,
    Users as UsersIcon,
    Settings as SettingsIcon,
    User as UserIcon,
    List as ListIcon,
    Clock as ClockIcon,
} from 'react-feather';
import {
    UI_APP_DASHBOARD,
    UI_APP_ASSIGNMENT,
    UI_APP_EMPLOYEE,
    UI_APP_USERS,
    UI_APP_SYSTEM,
    UI_APP_ACCOUNT,
    UI_APP_TIME_LOG,
    UI_APP_ORDER,
} from 'src/defs/paths';
import {ROLE_TO_LABEL} from 'src/defs/constants';
import {Allowed} from 'src/components/Allowed';
import {Permission} from 'src/defs/rules';
import {useDeepEffect} from 'src/hooks/useDeepEffect';
import {getInitials} from 'src/utils/helpers';
import {localesTimeLog} from 'src/sections/timeLog/localesTimeLog';
import {locales} from 'src/sections/locales';

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256,
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)',
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64,
    },
    link: {
        textDecoration: 'none',
    },
}));

const isActive = (current: string, pathname: string): boolean => pathname.includes(current);

export const NavBar = ({openMobile, onMobileClose}: {openMobile: boolean; onMobileClose: () => void}) => {
    const classes = useStyles();
    const location = useLocation();
    const user = useUser();

    useDeepEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname]);

    const content = (
        <Box height='100%' display='flex' flexDirection='column'>
            <PerfectScrollbar options={{suppressScrollX: true}}>
                <Hidden lgUp>
                    <Box p={2} display='flex' justifyContent='center'>
                        <RouterLink to='/'>
                            <Logo />
                        </RouterLink>
                    </Box>
                </Hidden>
                <Box p={2}>
                    <Box display='flex' justifyContent='center'>
                        <RouterLink to={UI_APP_ACCOUNT} className={classes.link}>
                            <Avatar alt={user.name} className={classes.avatar}>
                                {getInitials(user.name)}
                            </Avatar>
                        </RouterLink>
                    </Box>
                    <Box mt={2} textAlign='center'>
                        <Link
                            component={RouterLink}
                            to={UI_APP_ACCOUNT}
                            variant='h5'
                            color='textPrimary'
                            underline='none'
                        >
                            {user.name}
                        </Link>
                        <Typography variant='body2' color='textSecondary'>
                            {ROLE_TO_LABEL[user.role]}
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <Box p={2}>
                    <NavItem title='Dashboard' href={UI_APP_DASHBOARD} icon={PieChartIcon} />
                    <Allowed resource={Permission.ASSIGNMENT_SHOW}>
                        <NavItem
                            title={locales.assignments}
                            href={UI_APP_ASSIGNMENT}
                            icon={ListIcon}
                            active={isActive(UI_APP_ASSIGNMENT, location.pathname)}
                        />
                    </Allowed>
                    <Allowed resource={Permission.ORDER_SHOW}>
                        <NavItem
                            title={locales.orders}
                            href={UI_APP_ORDER}
                            icon={ListIcon}
                            active={isActive(UI_APP_ORDER, location.pathname)}
                        />
                    </Allowed>
                    <Allowed resource={Permission.EMPLOYEE_SHOW}>
                        <NavItem
                            title={locales.employees}
                            href={UI_APP_EMPLOYEE}
                            icon={UserIcon}
                            active={isActive(UI_APP_EMPLOYEE, location.pathname)}
                        />
                    </Allowed>
                    <Allowed resource={Permission.USERS_SHOW}>
                        <NavItem
                            title={locales.userManagement}
                            href={UI_APP_USERS}
                            icon={UsersIcon}
                            active={isActive(UI_APP_USERS, location.pathname)}
                        />
                    </Allowed>

                    <Allowed resource={Permission.TIME_LOG_SHOW}>
                        <NavItem
                            title={localesTimeLog.timeLogs}
                            href={UI_APP_TIME_LOG}
                            icon={ClockIcon}
                            active={isActive(UI_APP_TIME_LOG, location.pathname)}
                        />
                    </Allowed>

                    <Allowed resource={Permission.SYSTEM_CONFIG_SHOW}>
                        <NavItem
                            title={locales.systemConfiguration}
                            href={UI_APP_SYSTEM}
                            icon={SettingsIcon}
                            active={isActive(UI_APP_SYSTEM, location.pathname)}
                        />
                    </Allowed>
                </Box>
            </PerfectScrollbar>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor='left'
                    classes={{paper: classes.mobileDrawer}}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant='temporary'
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer anchor='left' classes={{paper: classes.desktopDrawer}} open variant='persistent'>
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};
