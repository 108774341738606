import axios from 'src/utils/axios';
import authService from 'src/services/authService';
import {StoreActionUser, DEFAULT_USER} from 'src/defs/constants';
import {TypeUser} from 'src/defs/types';
import {Dispatch} from 'react';
import {API_ACCOUNT_ME} from 'src/defs/paths';

const loginRequest = () => ({type: StoreActionUser.LOGIN_REQUEST});
const loginFailure = () => ({type: StoreActionUser.LOGIN_FAILURE});
const loginLogout = () => ({type: StoreActionUser.LOGOUT});
const loginSuccess = (user: TypeUser) => ({
    type: StoreActionUser.LOGIN_SUCCESS,
    payload: {
        user,
    },
});
const loginSilent = (user: TypeUser) => ({
    type: StoreActionUser.SILENT_LOGIN,
    payload: {
        user,
    },
});
const logoutSilent = () => ({
    type: StoreActionUser.SILENT_LOGOUT,
    payload: {
        user: DEFAULT_USER,
    },
});
const loginUpdate = (user: Partial<TypeUser>) => ({
    type: StoreActionUser.UPDATE_PROFILE,
    payload: {user},
});

export const login = (email: string, password: string) => {
    return async (dispatch: Dispatch<ReturnType<typeof loginRequest | typeof loginSuccess | typeof loginFailure>>) => {
        try {
            dispatch(loginRequest());

            const user: TypeUser = await authService.loginWithEmailAndPassword(email, password);

            dispatch(loginSuccess(user));
        } catch (error) {
            dispatch(loginFailure());
            throw error;
        }
    };
};

export const setUserData = (user: TypeUser) => {
    return (dispatch: Dispatch<ReturnType<typeof loginSilent>>) => {
        dispatch(loginSilent(user));
    };
};

export const resetUserData = () => {
    return (dispatch: Dispatch<ReturnType<typeof logoutSilent>>) => {
        dispatch(logoutSilent());
    };
};

export const logout = () => {
    return async (dispatch: Dispatch<ReturnType<typeof loginLogout>>) => {
        await authService.logout();

        dispatch(loginLogout());
    };
};

export const register = () => {
    return true;
};

export const updateProfile = (update: Partial<TypeUser>) => {
    const request = axios.put(API_ACCOUNT_ME, {...update});

    return (dispatch: Dispatch<ReturnType<typeof loginUpdate>>) => {
        request.then((response) => dispatch(loginUpdate(response.data)));
    };
};
