import {localized} from 'src/utils/localized';

export const locales = localized({
    accountSettings: 'Account Settings',
    actions: 'Actions',
    active: 'Active',
    addNew: 'Add new',
    address: 'Address',
    agree: 'Agree',
    all: 'All',
    approve: 'Approve',
    approved: 'Approved',
    approveSelected: 'Approve selected',
    assignments: 'Assignments',
    back: 'Back to List',
    backToHome: 'Back to Home',
    cancel: 'Cancel',
    city: 'City',
    completed: 'Completed',
    confirmPassword: 'Confirm Password',
    country: 'Country',
    createdBy: 'Created By',
    createNewPassword: 'Create New Password',
    dash: '-',
    delete: 'Delete',
    deleteRecord: 'Delete record',
    disagree: 'Disagree',
    download: {
        title: 'Download CSV',
        markPaid: 'Mark data as paid',
        button: 'Download',
    },
    editRecord: 'Edit record',
    email: 'Email',
    employees: 'Employees',
    error404Primary: '404: The page you are looking for isn’t here',
    error404Secondary:
        'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.',
    fileRequired: 'You need to provide an attachment',
    files: 'Files',
    forgotPassword: 'Forgot Password',
    genericError: 'Something went wrong. Please try again later.',
    genericErrorShort: 'Something went wrong.',
    genericSuccess: 'Success',
    location: 'Location',
    login: 'Username / Email',
    markUnpaid: 'Mark as Unpaid',
    minDate: 'End date must be after start date.',
    moreThanZero: 'Value must be greater than or equal to one',
    moveToPending: 'Save as pending',
    name: 'Name',
    newPassword: 'New Password',
    no: 'no',
    onboarded: 'Onboarded',
    orders: 'Orders',
    organization: 'Choose an organization',
    passwordHint: '* Password must contain upper and lowercase letters, numbers, non alphanumeric characters.',
    passwordReset: 'The password has been reset!',
    pending: 'Pending',
    permissions: {
        PRIMARY_TIME_APPROVER: 'Primary time approver',
        RESOURCE_MANAGER: 'Resource manager',
        'system-config:show': 'System Config - Allow Show',
        'system-config:edit': 'System Config - Allow Modifications',
        'users:show': 'User - Allow Show',
        'users:edit': 'User - Allow Edit',
        'users:delete': 'User - Allow Delete',
        'users:activate': 'User - Allow Activate',
        'user-permissions:edit': 'User - Allow Permissions',
        'employee:show': 'Employee - Allow Show',
        'employee:edit': 'Employee - Allow Edit',
        'employee:delete': 'Employee - Allow Delete',
        'assignment:show': 'Assignment - Allow show',
        'assignment:edit': 'Assignment - Allow Edit',
        'assignment:approve': 'Assignment - Approve',
        'dashboard:stats': 'Enable Dashboard Widgets',
        'time:show': 'Enable Time Log module',
        'time:supervisor': 'Supervisor Time Approver',
    },
    personalInfo: 'Personal Information',
    phone: 'Phone',
    provision: 'Provision',
    provisioned: {
        approved: 'Onboarding completed',
        pending: 'Onboarding pending',
        rejected: 'Onboarding rejected',
    },
    readyForWork: 'Ready for Work',
    reject: 'Reject',
    rejected: 'Rejected',
    required: 'This field is required',
    requiredWhenApproving: 'This field is required when approving',
    requireNewPassword: 'A new password is required!',
    requireComplexPassword: 'Password is not complex enough!',
    requirePasswordConfirmation: 'Confirmation is required!',
    requirePasswordMatch: 'The passwords do not match!',
    reset: 'Reset',
    resetPassword: 'Reset Password',
    role: 'Role',
    save: 'Save',
    search: 'Search',
    seeAll: 'See All',
    showRecord: 'Show details',
    signIn: 'Sign In',
    signInInternal: 'Sign in on the internal platform',
    startTyping: 'Please start typing (min. 3 letters)',
    state: 'State',
    status: 'Status',
    switchTheme: 'Switch your theme',
    systemConfiguration: 'System Configuration',
    theme: 'Theme',
    update: 'Update',
    userManagement: 'User Management',
    username: 'Username',
    validDate: 'Value must be a valid date',
    validEmail: 'Must be a valid email',
    validInteger: 'Value must be without decimal',
    validNumber: 'Value must numeric',
    yes: 'yes',
    zip: 'ZIP',
});
