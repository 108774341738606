import React from 'react';
import {LANGUAGES, RecordStatus} from 'src/defs/constants';
import {Currency, DATE_FORMAT_UTC, DEFAULT_CURRENCY, DEFAULT_DATE_FORMAT} from 'src/defs/i18n';
import {formatNumber, TypeFormatNumberOpts} from 'src/utils/Num';
import {format as formatDate, isValid as isValidDate} from 'date-fns';
import {TypeLanguage} from 'src/defs/types';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import CachedIcon from '@material-ui/icons/Cached';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';

export const formatCurrency = (
    value: number | string,
    currency: Currency = DEFAULT_CURRENCY,
    opts: TypeFormatNumberOpts = Object.freeze({}),
): string => `${currency} ${formatNumber(value, opts)}`;

export const enumValueToIndex = <P,>(collection: P, value: keyof P): number =>
    Object.keys(collection).indexOf(String(value));

export const indexToEnumValue = <P, T>(collection: P, index: number): T =>
    (Object.keys(collection)[index] as unknown) as T;

export const dateParser = (value: string | null, format?: string) => {
    if (value === null) {
        return '';
    }

    const WORKFRONT_DASH_COUNT = 3;
    const workingValue = value.split('-');
    const workingDateCandidate =
        workingValue.length > WORKFRONT_DASH_COUNT
            ? workingValue
                  .map((item, index) => (index < WORKFRONT_DASH_COUNT ? item : null))
                  .filter(Boolean)
                  .join('-')
                  .replace(':000', '.000')
            : value;

    // Time from sever is in UTC format and we have to adjust the timestamp because formatDate will apply the client timezone
    const timestamp = Date.parse(workingDateCandidate) - new Date(workingDateCandidate).getTimezoneOffset() * -60000;

    if (!isNaN(timestamp)) {
        return formatDate(timestamp, format ?? DEFAULT_DATE_FORMAT);
    }

    return '';
};

/**
 * Time from server is in UTC format and we have to adjust the timestamp to show the correct date in KeyboardDatePicker.
 * Also, when user pick a date we adjust it to UTC format with fixTheDateForDatePickerOnChange, and here we will adjust the time
 * because KeyboardDatePicker is going to convert it user timezone.
 * @param userDate - this is the date set in KeyboardDatePicker value prop
 * @returns
 */
export const fixTheDateForDatePicker = (userDate: string | null | undefined): Date | string | null | undefined => {
    if (!userDate) {
        return userDate;
    }

    const date = new Date(userDate);

    if (!date || !isValidDate(date)) {
        return userDate;
    }

    const timestamp = date.getTime() - date.getTimezoneOffset() * -60000;

    if (!isNaN(timestamp)) {
        return new Date(timestamp);
    }

    return userDate;
};
/**
 * This is used by KeyboardDatePicker -> onChange to make sure we have the date in UTC format
 * @param date - date transformed by date picker
 * @param defaultValue - date set by user
 * @returns
 */
export const fixTheDateForDatePickerOnChange = (
    date: MaterialUiPickersDate,
    defaultValue: string | null | undefined,
): string | null | undefined => {
    if (!date || !isValidDate(date)) {
        return defaultValue;
    }

    return formatDate(Date.parse(date.toString()), DATE_FORMAT_UTC);
};

export const mapLanguageCodeToName = (code: string): string => {
    const [lang = {code: '', name: ''}] = LANGUAGES.filter((language: TypeLanguage) => language.code === code);

    return `${lang.name} - ${lang.code}`;
};

export const mapRecordStatusToIcon = (status: RecordStatus): React.ReactElement => {
    switch (status) {
        case RecordStatus.PENDING:
            return <CachedIcon />;
        case RecordStatus.APPROVED:
            return <DoneIcon />;
        case RecordStatus.REJECTED:
            return <CloseIcon />;
    }

    return <></>;
};

export const getInitials = (name: string = '') =>
    name
        .replace(/\s+/, ' ')
        .split(' ')
        .slice(0, 2)
        .map((v) => v && v[0].toUpperCase())
        .join('');

export const isInternalURL = (to: string) => {
    try {
        const url = new URL(to, window.location.origin);

        return url.hostname === window.location.hostname;
    } catch {
        return false;
    }
};
