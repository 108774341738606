import React from 'react';
import {BrowserRouter as ReactRouter} from 'react-router-dom';
import {create} from 'jss';
import rtl from 'jss-rtl';
import DateUtils from '@date-io/date-fns';
import {SnackbarProvider} from 'notistack';
import {createStyles, jssPreset, makeStyles, StylesProvider, ThemeProvider} from '@material-ui/core';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {Auth} from 'src/components/Auth';
import {ScrollReset} from 'src/components/ScrollReset';
import {useSettings} from 'src/hooks/useSettings';
import {createTheme} from 'src/theme';
import {Router} from 'src/Router';

const jss = create({plugins: [...jssPreset().plugins, rtl()]});

const useStyles = makeStyles((theme) =>
    createStyles({
        '@global': {
            '*': {
                boxSizing: 'border-box',
                margin: 0,
                padding: 0,
            },
            a: {
                color: theme.palette.info.dark,
            },
            html: {
                '-webkit-font-smoothing': 'antialiased',
                '-moz-osx-font-smoothing': 'grayscale',
                height: '100%',
                width: '100%',
            },
            body: {
                height: '100%',
                width: '100%',
                overflow: 'hidden',
            },
            '#root': {
                height: '100%',
                width: '100%',
            },
        },
    }),
);

const App = () => {
    useStyles();

    const {settings} = useSettings();

    return (
        <ThemeProvider theme={createTheme(settings)}>
            <StylesProvider jss={jss}>
                <MuiPickersUtilsProvider utils={DateUtils}>
                    <SnackbarProvider maxSnack={1} autoHideDuration={3000}>
                        <ReactRouter>
                            <Auth>
                                <>
                                    <ScrollReset />
                                    <Router />
                                </>
                            </Auth>
                        </ReactRouter>
                    </SnackbarProvider>
                </MuiPickersUtilsProvider>
            </StylesProvider>
        </ThemeProvider>
    );
};

export default App;
