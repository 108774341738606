import {merge} from 'lodash';
import {colors, createMuiTheme, responsiveFontSizes} from '@material-ui/core';
import typography from 'src/theme/typography';
import {softShadows, strongShadows} from 'src/theme/shadows';
import {Themes} from 'src/defs/constants';
import {TypeSettings} from 'src/defs/types';
import {Shadows} from '@material-ui/core/styles/shadows';

const baseConfig = {
    direction: <const>'ltr',
    typography,
    overrides: {
        MuiLinearProgress: {
            root: {
                borderRadius: 3,
                overflow: 'hidden',
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 32,
            },
        },
        MuiChip: {
            root: {
                backgroundColor: 'rgba(0,0,0,0.075)',
            },
        },
        MuiCardHeader: {
            title: {
                fontSize: 18,
            },
        },
        MUIDataTableHeadCell: {
            root: {
                fontWeight: 'bold',
            },
            toolButton: {
                fontWeight: 'bold',
            },
        },
        MUIDataTablePagination: {
            tableCellContainer: {
                padding: 0,
            },
        },
        MUIDataTableToolbar: {
            root: {
                '@media (max-width: 600px)': {
                    padding: '0 8px',
                },
            },
        },
        MuiTableCell: {
            root: {
                padding: '16px 2px',

                '&:first-of-type': {
                    paddingLeft: '16px',
                },
                '&:last-of-type': {
                    paddingRight: '16px',
                },
            },
            body: {
                wordWrap: 'break-word',
                overflow: 'hidden',
            },
        },
        MuiTable: {
            root: {
                tableLayout: 'fixed',
                '& .datatables-noprint': {
                    width: '100%',
                },
            },
        },
        MUIDataTableBodyCell: {
            stackedHeader: {
                fontWeight: 'bold',
            },

            root: {
                '& a': {
                    padding: '0 8px 0 0',
                },
            },
        },
        MUIDataTableFilter: {
            root: {minWidth: 200},
            resetLink: {display: 'none'},
        },
        MUIDataTableSearch: {
            searchText: {
                flex: '0.6 0',
                '@media (max-width: 600px)': {
                    flex: '1 0',
                },
            },
        },
    },
};

const themeConfigs = [
    {
        name: Themes.LIGHT,
        overrides: {
            MuiInputBase: {
                input: {
                    '&::placeholder': {
                        opacity: 1,
                        color: colors.blueGrey[600],
                    },
                },
            },
        },
        palette: {
            type: <const>'light',
            action: {
                active: colors.blueGrey[600],
            },
            paletteBackground: {
                dark: '#f4f6f8',
            },
            background: {
                default: colors.common.white,
                paper: colors.common.white,
            },
            primary: {
                main: colors.indigo[600],
            },
            secondary: {
                main: '#5850EC',
            },
            text: {
                primary: colors.blueGrey[900],
                secondary: colors.blueGrey[600],
            },
        },
        shadows: softShadows as Shadows,
    },
    {
        name: Themes.ONE_DARK,
        palette: {
            type: <const>'dark',
            action: {
                active: 'rgba(255, 255, 255, 0.54)',
                hover: 'rgba(255, 255, 255, 0.04)',
                selected: 'rgba(255, 255, 255, 0.08)',
                disabled: 'rgba(255, 255, 255, 0.26)',
                disabledBackground: 'rgba(255, 255, 255, 0.12)',
                focus: 'rgba(255, 255, 255, 0.12)',
            },
            paletteBackground: {
                dark: '#1c2025',
            },
            background: {
                default: '#282C34',
                paper: '#282C34',
            },
            primary: {
                main: '#8a85ff',
            },
            secondary: {
                main: '#8a85ff',
            },
            text: {
                primary: '#e6e5e8',
                secondary: '#adb0bb',
            },
        },
        shadows: strongShadows as Shadows,
    },
];

export const createTheme = (settings: Partial<TypeSettings> = {}) => {
    let themeConfig = themeConfigs.find((theme) => theme.name === settings.theme);

    if (!themeConfig) {
        [themeConfig] = themeConfigs;
    }

    const theme = createMuiTheme(merge({}, baseConfig, themeConfig, {direction: settings.direction}));

    return !settings.responsiveFontSizes ? theme : responsiveFontSizes(theme);
};
