import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import {AppBar, Box, Toolbar, makeStyles} from '@material-ui/core';
import {Logo} from 'src/components/Logo';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
    },
    toolbar: {
        height: 64,
    },
    logo: {
        marginRight: theme.spacing(2),
        height: 32,
    },
    link: {
        fontWeight: theme.typography.fontWeightMedium,
        '& + &': {
            marginLeft: theme.spacing(2),
        },
        textDecoration: 'none',
    },
    divider: {
        width: 1,
        height: 32,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
}));

export const TopBar = ({className}: {className?: Pick<HTMLDivElement, 'className'>}) => {
    const classes = useStyles();

    return (
        <AppBar className={clsx(classes.root, className)} color='default'>
            <Toolbar className={classes.toolbar}>
                <RouterLink to='/'>
                    <Logo className={classes.logo} />
                </RouterLink>
                <Box flexGrow={1} />
            </Toolbar>
        </AppBar>
    );
};
