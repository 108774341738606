export const API_BASE_URL = '/api';
export const API_USERS = `${API_BASE_URL}/users`;
export const API_APPROVERS = `${API_BASE_URL}/approvers`;
export const API_USER = `${API_BASE_URL}/user/:id`;
export const API_USER_PATCH = `${API_BASE_URL}/user/:id/patch`;
export const API_USER_FIND = `${API_BASE_URL}/user/find/:attr/:value`;
export const API_ACCOUNT_LOGIN = `${API_BASE_URL}/account/login`;
export const API_ACCOUNT_ME = `${API_BASE_URL}/account/me`;
export const API_ACCOUNT_LOGOUT = `${API_BASE_URL}/account/logout`;

export const API_ASSIGNMENTS = `${API_BASE_URL}/assignments/:status?`;
export const API_ASSIGNMENT = `${API_BASE_URL}/assignment/:id`;
export const API_ASSIGNMENT_TIME_TRACKING = `${API_BASE_URL}/time/assignment/:assignmentId`;
export const API_ASSIGNMENT_TIME_TRACKING_TASK = `${API_BASE_URL}/time/assignment/:assignmentId/task`;
export const API_ASSIGNMENT_SEARCH = `${API_BASE_URL}/search/assignment/:organization/keyword/:keyword`;

export const API_ORDERS = `${API_BASE_URL}/orders/:status?`;
export const API_ORDER = `${API_BASE_URL}/order/:id`;
export const API_ORDER_EMPLOYEES = `${API_BASE_URL}/employees/order`;

export const API_USER_FORGOT_PASSWORD = `${API_BASE_URL}/account/forgot`;
export const API_USER_RESET_PASSWORD = `${API_BASE_URL}/account/reset/`;

export const API_EMPLOYEES = `${API_BASE_URL}/employees`;
export const API_EMPLOYEE = `${API_BASE_URL}/employee/:id`;
export const API_EMPLOYEE_TIME_TRACKING = `${API_BASE_URL}/time/employee/:username`;
export const API_EMPLOYEE_ASSIGNMENTS = `${API_BASE_URL}/employee/:employeeId/assignments`;
export const API_EMPLOYEE_FIND = `${API_BASE_URL}/employee/find/:attr/:value`;
export const API_EMPLOYEE_SEARCH = `${API_BASE_URL}/search/employee/:organization?/keyword/:keyword`;

export const API_DASHBOARD_ASSIGNMENTS = `${API_BASE_URL}/dashboard/assignments`;
export const API_DASHBOARD_ORDERS = `${API_BASE_URL}/dashboard/orders`;

export const API_TIME_TRACKING = `${API_BASE_URL}/time/all/:status?`;
export const API_TIME_TRACKING_STATUS = `${API_BASE_URL}/time/:logId/status`;
export const API_TIME_TRACKING_BULK_APPROVE = `${API_BASE_URL}/time/approve`;
export const API_TIME_TRACKING_BULK_REJECTED = `${API_BASE_URL}/time/reject`;
export const API_TIME_TRACKING_VERIFY = `${API_BASE_URL}/time/:logId/verify`;
export const API_TIME_TRACKING_LOGS = `${API_BASE_URL}/time/logs`;
export const API_TIME_TRACKING_LOG = `${API_BASE_URL}/time/log/:logId`;
export const API_TIME_TRACKING_BULK_PAID = `${API_BASE_URL}/time/paid`;
export const API_TIME_TRACKING_BULK_UNPAID = `${API_BASE_URL}/time/unpaid`;
export const API_TIME_TRACKING_ORDER_LOGS = `${API_BASE_URL}/time/order/:orderId`;
export const API_TIME_LOG_ORDER = `${API_BASE_URL}/time/log/order/:orderId`;

export const UI_ROOT = '/';
export const UI_HOME = '/home';
export const UI_LOGIN = '/login';
export const UI_FORGOT_PASSWORD = '/forgot';
export const UI_RESET_PASSWORD = '/reset/:token';
export const UI_NOT_FOUND = '/404';
export const UI_APP = '/app';
export const UI_APP_DASHBOARD = '/app/dashboard';
export const UI_APP_ACCOUNT = '/app/account';

export const UI_APP_USERS = '/app/users';
export const UI_APP_USER_DETAILS = '/app/user/:id/profile';
export const UI_APP_USER_EDIT = '/app/user/:id/edit';
export const UI_APP_USER_CREATE = '/app/user/create';

export const UI_APP_EMPLOYEE = '/app/employee';

// Old assignments routes start
export const UI_APP_EMPLOYEE_MANAGEMENT = '/app/assignment/employee/:action/:id?/:assignmentId?';
export const UI_APP_EMPLOYEE_MANAGEMENT_PROVISION = '/app/assignment/provision/employee/:action/:id/:assignmentId';
export const UI_APP_EMPLOYEE_TO_ASSIGNMENT = '/app/assignment/employee/:action/:assignmentId/:employeeName?';
// Old assignments routes end

export const UI_APP_ORDER_EMPLOYEE_MANAGEMENT = '/app/employee/:action/:id?/:orderId?';
export const UI_APP_ORDER_EMPLOYEE_MANAGEMENT_PROVISION = '/app/provision/employee/:action/:id/:orderId';
export const UI_APP_ORDER_EMPLOYEE_TO_ASSIGNMENT = '/app/assign/employee/:action/:orderId/:employeeName?';

export const UI_APP_ORDER = '/app/order';
export const UI_APP_ORDER_MANAGEMENT = '/app/order/:action/:id?/:employeeId?';

export const UI_APP_ASSIGNMENT = '/app/assignment';
export const UI_APP_ASSIGNMENT_MANAGEMENT = '/app/assignment/:action/:id?/:employeeId?';
export const UI_APP_TIME_LOG = '/app/time';

export const UI_APP_SYSTEM = '/app/system';
export const UI_APP_SYSTEM_LIST = '/app/system/:tab/';
export const UI_APP_SYSTEM_MANAGEMENT = '/app/system/:tab/:action/:id?';

export const API_SYSTEM_PROJECTS = `${API_BASE_URL}/system/projects`;
export const API_SYSTEM_PROJECT = `${API_BASE_URL}/system/project/:projectId`;
export const API_SYSTEM_PROJECT_ROLES_SEARCH = `${API_BASE_URL}/system/search/project_roles/:organization?`;
export const API_SYSTEM_PROJECT_ROLES = `${API_BASE_URL}/system/project_roles/`;
export const API_SYSTEM_PROJECT_ROLE = `${API_BASE_URL}/system/project_roles/:projectRoleId`;
export const API_SYSTEM_AGENCIES = `${API_BASE_URL}/system/agencies`;
export const API_SYSTEM_AGENCY = `${API_BASE_URL}/system/agency/:agencyId`;
export const API_SYSTEM_LOCATIONS = `${API_BASE_URL}/system/locations`;
export const API_SYSTEM_LOCATION = `${API_BASE_URL}/system/location/:locationId`;
export const API_SYSTEM_LOCATION_SEARCH = `${API_BASE_URL}/system/search/locations/:organization?`;

export const API_WORKFRONT_SEARCH_NAME = `${API_BASE_URL}/workfront/search/:organization/name/:name`;
export const API_WORKFRONT_SEARCH_NUMBER = `${API_BASE_URL}/workfront/search/:organization/reference/:referenceNumber`;
