import React, {Suspense} from 'react';
import {Switch, Route, RouteProps, Redirect} from 'react-router-dom';
import {GuestLayout} from 'src/layouts/GuestLayout';
import {InternalLayout} from 'src/layouts/InternalLayout';
import {LoadingScreen} from 'src/components/LoadingScreen';
import loadable, {LoadableComponent} from '@loadable/component';
import {useUser} from 'src/hooks/useUser';
import {
    UI_ROOT,
    UI_HOME,
    UI_APP_DASHBOARD,
    UI_APP,
    UI_NOT_FOUND,
    UI_APP_ASSIGNMENT,
    UI_APP_EMPLOYEE,
    UI_APP_USERS,
    UI_APP_SYSTEM,
    UI_APP_ACCOUNT,
    UI_LOGIN,
    UI_FORGOT_PASSWORD,
    UI_RESET_PASSWORD,
    UI_APP_USER_EDIT,
    UI_APP_USER_DETAILS,
    UI_APP_USER_CREATE,
    UI_APP_ASSIGNMENT_MANAGEMENT,
    UI_APP_EMPLOYEE_TO_ASSIGNMENT,
    UI_APP_SYSTEM_MANAGEMENT,
    UI_APP_SYSTEM_LIST,
    UI_APP_EMPLOYEE_MANAGEMENT_PROVISION,
    UI_APP_TIME_LOG,
    UI_APP_ORDER,
    UI_APP_ORDER_MANAGEMENT,
    UI_APP_ORDER_EMPLOYEE_MANAGEMENT,
    UI_APP_ORDER_EMPLOYEE_MANAGEMENT_PROVISION,
    UI_APP_ORDER_EMPLOYEE_TO_ASSIGNMENT,
    UI_APP_EMPLOYEE_MANAGEMENT,
} from 'src/defs/paths';

const GuestRenderer = (Component: LoadableComponent<any>) => {
    const user = useUser();

    return (routeProps: RouteProps) => {
        return (
            <GuestLayout {...routeProps}>
                {user.id ? <Redirect to='/app' /> : <Component fallback={<LoadingScreen />} />}
            </GuestLayout>
        );
    };
};

const AuthRenderer = (Component: LoadableComponent<any>) => {
    const user = useUser();

    return (routeProps: RouteProps) => {
        return (
            <InternalLayout {...routeProps}>
                {!user.id ? <Redirect to='/login' /> : <Component fallback={<LoadingScreen />} />}
            </InternalLayout>
        );
    };
};

const SimpleRenderer = (Component: LoadableComponent<any>) => {
    return (routeProps: RouteProps) => {
        return <Component {...routeProps} fallback={<LoadingScreen />} />;
    };
};

export const Router = () => {
    return (
        <Suspense fallback={<LoadingScreen />}>
            <Switch>
                <Redirect exact from={UI_ROOT} to={UI_HOME} />
                <Redirect exact from={UI_APP} to={UI_APP_DASHBOARD} />

                <Route
                    exact
                    path={UI_NOT_FOUND}
                    render={SimpleRenderer(loadable(() => import('src/sections/Error404Page')))}
                />

                <Route
                    exact={true}
                    path={UI_APP_DASHBOARD}
                    render={AuthRenderer(loadable(() => import('src/sections/dashboard/Dashboard')))}
                />

                <Route
                    exact={true}
                    path={UI_APP_ORDER}
                    render={AuthRenderer(loadable(() => import('src/sections/order/OrderList')))}
                />

                <Route
                    exact={true}
                    path={UI_APP_ORDER_MANAGEMENT}
                    render={AuthRenderer(loadable(() => import('src/sections/order/OrderManagement')))}
                />

                <Route
                    exact={true}
                    path={UI_APP_ASSIGNMENT}
                    render={AuthRenderer(loadable(() => import('src/sections/assignment/AssignmentList')))}
                />

                <Route
                    exact={true}
                    path={UI_APP_ASSIGNMENT_MANAGEMENT}
                    render={AuthRenderer(loadable(() => import('src/sections/assignment/AssignmentManagement')))}
                />

                <Route
                    exact={true}
                    path={UI_APP_EMPLOYEE}
                    render={AuthRenderer(loadable(() => import('src/sections/employee/EmployeeList')))}
                />

                <Route
                    exact={true}
                    path={UI_APP_ORDER_EMPLOYEE_MANAGEMENT}
                    render={AuthRenderer(loadable(() => import('src/sections/employee/EmployeeManagement')))}
                />

                <Route
                    exact={true}
                    path={UI_APP_ORDER_EMPLOYEE_TO_ASSIGNMENT}
                    render={AuthRenderer(loadable(() => import('src/sections/employee/EmployeeManagement')))}
                />

                <Route
                    exact={true}
                    path={UI_APP_ORDER_EMPLOYEE_MANAGEMENT_PROVISION}
                    render={AuthRenderer(loadable(() => import('src/sections/employee/EmployeeManagement')))}
                />

                {/* Old assignments start */}
                <Route
                    exact={true}
                    path={UI_APP_EMPLOYEE_MANAGEMENT}
                    render={AuthRenderer(loadable(() => import('src/sections/assignment/EmployeeManagement')))}
                />

                <Route
                    exact={true}
                    path={UI_APP_EMPLOYEE_TO_ASSIGNMENT}
                    render={AuthRenderer(loadable(() => import('src/sections/assignment/EmployeeManagement')))}
                />

                <Route
                    exact={true}
                    path={UI_APP_EMPLOYEE_MANAGEMENT_PROVISION}
                    render={AuthRenderer(loadable(() => import('src/sections/assignment/EmployeeManagement')))}
                />
                {/* Old assignments end */}

                <Route
                    exact={true}
                    path={UI_APP_USERS}
                    render={AuthRenderer(loadable(() => import('src/sections/user/UserList')))}
                />

                <Route
                    exact={true}
                    path={UI_APP_USER_DETAILS}
                    render={AuthRenderer(loadable(() => import('src/sections/user/UserDetails')))}
                />

                <Route
                    exact={true}
                    path={UI_APP_USER_EDIT}
                    render={AuthRenderer(loadable(() => import('src/sections/user/UserEdit')))}
                />

                <Route
                    exact={true}
                    path={UI_APP_USER_CREATE}
                    render={AuthRenderer(loadable(() => import('src/sections/user/UserCreate')))}
                />

                <Route
                    exact={true}
                    path={UI_APP_TIME_LOG}
                    render={AuthRenderer(loadable(() => import('src/sections/timeLog/TimeLogList')))}
                />

                <Route
                    exact={true}
                    path={UI_APP_SYSTEM}
                    render={AuthRenderer(loadable(() => import('src/sections/systemConfig/SystemConfig')))}
                />

                <Route
                    exact={true}
                    path={UI_APP_SYSTEM_MANAGEMENT}
                    render={AuthRenderer(loadable(() => import('src/sections/systemConfig/SystemConfig')))}
                />

                <Route
                    exact={true}
                    path={UI_APP_SYSTEM_LIST}
                    render={AuthRenderer(loadable(() => import('src/sections/systemConfig/SystemConfig')))}
                />

                <Route
                    exact={true}
                    path={UI_APP_ACCOUNT}
                    render={AuthRenderer(loadable(() => import('src/sections/account/MyAccount')))}
                />

                <Route
                    exact={true}
                    path={UI_HOME}
                    render={GuestRenderer(loadable(() => import('src/sections/login/Login')))}
                />

                <Route
                    exact={true}
                    path={UI_LOGIN}
                    render={GuestRenderer(loadable(() => import('src/sections/login/Login')))}
                />

                <Route
                    exact={true}
                    path={UI_FORGOT_PASSWORD}
                    render={GuestRenderer(loadable(() => import('src/sections/login/ForgotPassword')))}
                />

                <Route
                    exact={true}
                    path={UI_RESET_PASSWORD}
                    render={GuestRenderer(loadable(() => import('src/sections/login/ResetPassword')))}
                />

                <Redirect to={UI_NOT_FOUND} />
            </Switch>
        </Suspense>
    );
};
