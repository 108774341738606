import React, {useRef, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSnackbar} from 'notistack';
import {Avatar, Box, ButtonBase, Hidden, Menu, MenuItem, Typography, makeStyles} from '@material-ui/core';
import {logout} from 'src/store/actions/accountActions';
import {useUser} from 'src/hooks/useUser';
import {UI_APP_ACCOUNT} from 'src/defs/paths';
import {getInitials} from 'src/utils/helpers';
import {locales} from 'src/sections/locales';

const useStyles = makeStyles((theme) => ({
    avatar: {
        height: 32,
        width: 32,
        marginRight: theme.spacing(1),
    },
    popover: {
        width: 200,
    },
}));

export const Account = () => {
    const classes = useStyles();
    const history = useHistory();
    const ref = useRef(null);
    const dispatch = useDispatch();
    const user = useUser();
    const {enqueueSnackbar} = useSnackbar();
    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = async () => {
        try {
            handleClose();
            await dispatch(logout());
            history.push('/');
        } catch (error) {
            enqueueSnackbar('Unable to logout', {
                variant: 'error',
            });
        }
    };

    return (
        <>
            <Box display='flex' alignItems='center' component={ButtonBase} onClick={handleOpen}>
                <div ref={ref} />
                <Avatar alt={user.name} className={classes.avatar}>
                    {getInitials(user.name)}
                </Avatar>
                <Hidden smDown>
                    <Typography variant='h6' color='inherit'>
                        {user.name}
                    </Typography>
                </Hidden>
            </Box>
            <Menu
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                keepMounted
                PaperProps={{className: classes.popover}}
                getContentAnchorEl={null}
                anchorEl={ref.current}
                open={isOpen}
            >
                <MenuItem component={RouterLink} to={UI_APP_ACCOUNT}>
                    {locales.accountSettings}
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </>
    );
};
