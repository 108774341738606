import axios from 'src/utils/axios';
import {HttpStatusCode} from 'src/defs/httpStatusCode';
import {SnackbarMessage, OptionsObject} from 'notistack';
import {PATHS_WITH_OWN_ERROR_HANDLER} from 'src/defs/constants';
import {locales} from 'src/sections/locales';

export const setAxiosInterceptors = ({
    onLogout,
    enqueueSnackbar,
}: {
    onLogout: () => void;
    enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => void;
}) => {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response) {
                const genericMessage = error.response.data?.message ?? locales.genericError;
                const authErrorMessage = error.response.data?.message ?? 'This request could not be authorized.';
                const permissionErrorMessage = error.response.data?.message ?? 'You need a higher level of permission.';

                switch (error.response.status) {
                    case HttpStatusCode.UNAUTHORIZED:
                        onLogout();
                        enqueueSnackbar(authErrorMessage, {variant: 'error', preventDuplicate: true, persist: false});
                        break;
                    case HttpStatusCode.FORBIDDEN:
                        enqueueSnackbar(`Access Denied: ${permissionErrorMessage}`, {
                            variant: 'error',
                            preventDuplicate: true,
                            persist: false,
                        });
                        break;
                    default:
                        if (!PATHS_WITH_OWN_ERROR_HANDLER.includes(error.config.url)) {
                            enqueueSnackbar(genericMessage, {variant: 'error', preventDuplicate: true, persist: false});
                        }
                        break;
                }
            }

            return Promise.reject(error);
        },
    );
};

export const setAxiosDefaults = ({token}: {token: string}) => {
    axios.defaults.headers = {
        Authorization: token,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
    };
};
