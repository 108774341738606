import React, {useState, useRef} from 'react';
import {capitalCase} from 'change-case';
import {Box, Button, IconButton, Popover, SvgIcon, TextField, Tooltip, Typography, makeStyles} from '@material-ui/core';
import {Sun as SettingsIcon} from 'react-feather';
import {useSettings} from 'src/hooks/useSettings';
import {Themes} from 'src/defs/constants';
import {locales} from 'src/sections/locales';

const useStyles = makeStyles((theme) => ({
    popover: {
        width: 320,
        padding: theme.spacing(2),
    },
}));

export const Settings = () => {
    const classes = useStyles();
    const ref = useRef(null);
    const {settings, saveSettings} = useSettings();
    const [isOpen, setOpen] = useState(false);
    const [values, setValues] = useState({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        theme: settings.theme,
    });

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (field: string, value: string | boolean) => {
        setValues({
            ...values,
            [field]: value,
        });
    };

    const handleSave = () => {
        saveSettings(values);
        setOpen(false);
    };

    return (
        <>
            <Tooltip title={locales.theme}>
                <IconButton color='inherit' onClick={handleOpen} ref={ref}>
                    <SvgIcon fontSize='small'>
                        <SettingsIcon />
                    </SvgIcon>
                </IconButton>
            </Tooltip>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{paper: classes.popover}}
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
            >
                <Typography variant='h4' color='textPrimary'>
                    {locales.switchTheme}
                </Typography>
                <Box mt={2}>
                    <TextField
                        fullWidth
                        label={locales.theme}
                        name='theme'
                        onChange={(event) => handleChange('theme', event.target.value)}
                        select
                        SelectProps={{native: true}}
                        value={values.theme}
                        variant='outlined'
                    >
                        {Object.keys(Themes).map((theme) => (
                            <option key={theme} value={theme}>
                                {capitalCase(theme)}
                            </option>
                        ))}
                    </TextField>
                </Box>
                <Box mt={2}>
                    <Button variant='contained' color='secondary' fullWidth onClick={handleSave}>
                        {locales.save}
                    </Button>
                </Box>
            </Popover>
        </>
    );
};
