import makeString from 'src/utils/str/makeString';

const chars = (str: string): string[] => {
    return makeString(str).split('');
};

// eslint-disable-next-line max-params
const splice = (str: string, i: number, howmany: number, substr: string): string => {
    const arr = chars(str);

    arr.splice(~~i, ~~howmany, substr); // eslint-disable-line no-bitwise

    return arr.join('');
};

const insert = (str: string, i: number, substr: string): string => {
    return splice(str, i, 0, substr);
};

export default insert;
