import axios from 'src/utils/axios';
import {TypeUser} from 'src/defs/types';
import {API_ACCOUNT_LOGIN, API_ACCOUNT_ME, API_ACCOUNT_LOGOUT} from 'src/defs/paths';
import {setAxiosDefaults} from 'src/services/networkService';

class AuthService {
    loginWithEmailAndPassword = (email: string, password: string): Promise<TypeUser> =>
        new Promise((resolve, reject) => {
            axios
                .post(API_ACCOUNT_LOGIN, {email, password})
                .then((response) => {
                    if (response.data.user) {
                        this.setToken(response.data.token);
                        resolve(response.data.user);
                    } else {
                        reject(response.data.error);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });

    loginInWithToken = (): Promise<TypeUser> =>
        new Promise((resolve, reject) => {
            axios
                .get(API_ACCOUNT_ME)
                .then((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(response.data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });

    logout = () => {
        new Promise((resolve, reject) => {
            axios
                .post(API_ACCOUNT_LOGOUT)
                .then((response) => {
                    resolve({});
                    this.setToken(null);
                })
                .catch((error) => {
                    reject(error);
                    this.setToken(null);
                });
        });
    };

    setToken = (token: string | null) => {
        if (token !== null) {
            localStorage.setItem('token', token);
        } else {
            localStorage.removeItem('token');
        }

        this.setAxiosAuthHeader();
    };

    setAxiosAuthHeader = () => {
        setAxiosDefaults({token: ''});

        if (this.hasAccessToken()) {
            setAxiosDefaults({token: `Bearer ${this.getAccessToken()}`});
        }
    };

    getAccessToken = () => localStorage.getItem('token');

    hasAccessToken = (): boolean => Boolean(this.getAccessToken());
}

const authService = new AuthService();

export default authService;
