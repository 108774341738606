import React from 'react';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 32,
    },
}));

export const Logo = (props: React.HTMLAttributes<HTMLImageElement>) => {
    const classes = useStyles();

    return <img alt='Logo' src='/static/nexeo.png' className={classes.root} {...props} />;
};
