import {isNaN as lodashIsNaN, trim} from 'lodash';
import {numberFormat, join, replaceAll} from 'src/utils/Str';
import insert from 'src/utils/str/insert';

export const THOUSANDS_SEPARATOR = ',';

export const DECIMAL_SEPARATOR = '.';
export const NUMBER_FORMATTER_DEFAULTS = {
    convertEmptyStringToZero: true,
    thousandsSeparator: THOUSANDS_SEPARATOR,
    decimalSeparator: DECIMAL_SEPARATOR,
    allowThousandsSeparator: true,
    limitMethod: 'round',
    allowLeadingZeroes: true,
};

export type TypeFormatNumberOpts = {
    allowLeadingZeroes?: boolean;
    allowThousandsSeparator?: boolean;
    convertEmptyStringToZero?: boolean;
    decimalSeparator?: string;
    fixedDecimals?: number;
    limitMethod?: string;
    maxDecimals?: number;
    maxDigits?: number;
    minDecimals?: number;
    thousandsSeparator?: string;
};

export const NUMBER_SCALE_ABBREVIATION = {
    thousand: 'k',
    million: 'M',
    billion: 'B',
    trillion: 'T',
};

const thousandAbbrUnit = (timesReduced: number) => {
    switch (timesReduced) {
        case 1:
            return NUMBER_SCALE_ABBREVIATION.thousand;
        case 2:
            return NUMBER_SCALE_ABBREVIATION.million;
        case 3:
            return NUMBER_SCALE_ABBREVIATION.billion;
        case 4:
            return NUMBER_SCALE_ABBREVIATION.trillion;
        default:
            return '';
    }
};

export const thousandAbbr = (value: number, opts: TypeFormatNumberOpts = {}): string => {
    if (value === 0) {
        return String(value);
    }

    const thousand = 1000;
    const prefix = value < 0 ? '-' : '';
    const absValue = Math.abs(value);
    const timesDivided = Math.floor(Math.log10(absValue) / 3);
    const result = absValue / thousand ** timesDivided;

    return `${prefix}${formatNumber(result, {maxDecimals: 2, ...opts})}${thousandAbbrUnit(timesDivided)}`;
};

export function getFormatNumberOptions(opts: TypeFormatNumberOpts): TypeFormatNumberOpts {
    return {...NUMBER_FORMATTER_DEFAULTS, ...opts};
}

export function removeSeparator(value: string | number | null, sep: string): string {
    if (value == null) {
        return '';
    }

    return value
        .toString()
        .replace(/\s/g, '')
        .replace(new RegExp(`\\${sep}`, 'img'), '');
}

function removeThousandsSeparator(value: string | number) {
    return removeSeparator(value, THOUSANDS_SEPARATOR);
}

function numberOrDefault(rawNumber: any, defaultValue: any): any {
    let number = rawNumber;

    if (number == null || lodashIsNaN(number)) {
        number = defaultValue;
    }

    return number;
}

export const unformatNumber = (value: string | number, defaultValue: string | number = 0): number => {
    return numberOrDefault(
        parseFloat(removeThousandsSeparator(numberOrDefault(value, defaultValue).toString())),
        defaultValue,
    );
};

export const isNumeric = (value: null | undefined | string | number): boolean => {
    return (
        value != null && trim(String(value)) !== '' && 'number' === typeof Number(value) && !lodashIsNaN(Number(value))
    );
};

export const minDecimals = (rawValue: number | string, decimalCount: number): string => {
    let value = rawValue;

    value = value.toString();

    if (decimalCount > 0) {
        if (value.indexOf(DECIMAL_SEPARATOR) === -1) {
            value += DECIMAL_SEPARATOR + new Array(decimalCount + 1).join('0');
        } else {
            const currentDecimals = value.split(DECIMAL_SEPARATOR)[1];

            if (decimalCount > currentDecimals.length) {
                value += new Array(decimalCount - currentDecimals.length + 1).join('0');
            }
        }
    }

    return value;
};

export const maxDecimals = (
    rawValue: number | string,
    decimalCount: number,
    method: string = NUMBER_FORMATTER_DEFAULTS.limitMethod,
): string => {
    let value = replaceAll(String(rawValue), THOUSANDS_SEPARATOR, '');
    const decimalsMultiplier = 10 ** decimalCount;
    const initialIntegerPart = String(rawValue).split(DECIMAL_SEPARATOR)[0];
    const initialDecimalPart = String(rawValue).split(DECIMAL_SEPARATOR)[1];

    if (decimalCount >= 0 && initialDecimalPart && initialDecimalPart.length > decimalCount) {
        switch (method) {
            case 'cut':
                value = String(parseInt(String(Number(value) * decimalsMultiplier)) / decimalsMultiplier);
                break;
            case 'round':
                if (decimalCount === 0) {
                    value = String(Math.round(Number(value)));
                } else {
                    value = String(Math.round(Number(value) * decimalsMultiplier) / decimalsMultiplier);
                }
                break;
        }
    }

    if (decimalCount > 0) {
        const decimals = value.split(DECIMAL_SEPARATOR)[1];

        if (decimals) {
            value = join(DECIMAL_SEPARATOR, initialIntegerPart, decimals);
        }
    } else {
        for (let i = 0; i < initialIntegerPart.length; i++) {
            if (initialIntegerPart[i] === THOUSANDS_SEPARATOR) {
                value = insert(value, i, THOUSANDS_SEPARATOR);
            }
        }

        value = value.split(DECIMAL_SEPARATOR)[0];
    }

    return value;
};

export const fixedDecimals = (
    rawValue: number | string,
    decimals: number,
    method: string = NUMBER_FORMATTER_DEFAULTS.limitMethod,
): string => {
    return minDecimals(maxDecimals(rawValue, decimals, method), decimals);
};

export const formatNumber = (rawValue: string | number, rawOpts: TypeFormatNumberOpts = Object.freeze({})): string => {
    let value = rawValue;
    const options = getFormatNumberOptions(rawOpts);
    const maxDigits = isNumeric(options.maxDigits) ? parseInt(String(options.maxDigits)) : null;

    // Remove thousands separator
    value = removeThousandsSeparator(value);

    // Remove leading . if there is no decimal in place
    if (value.indexOf(DECIMAL_SEPARATOR) === value.length - 1) {
        value = value.substring(0, value.indexOf(DECIMAL_SEPARATOR));
    }

    // Remove octal literals
    if (options.allowLeadingZeroes === false) {
        value = value.replace(/^0+/, '0');
    }

    // Remove trailing - if there is no value
    if (value === '-') {
        value = '';
    }

    if ((options.convertEmptyStringToZero !== false && value.length === 0) || value.length > 0) {
        if (!value.length) {
            value = '0';
        }

        if (maxDigits != null) {
            while (value.replace(/[^0-9]/g, '').length > maxDigits || value[value.length - 1] === DECIMAL_SEPARATOR) {
                value = value.substr(0, value.length - 1);
            }
        }

        if (options.fixedDecimals != null) {
            value = fixedDecimals(value, options.fixedDecimals, options.limitMethod);
        } else {
            // Do maxDecimals first -- if we have minDecimals as well,
            // That function will add trailing zeroes which maxDecimals does not
            if (options.maxDecimals != null) {
                value = maxDecimals(value, options.maxDecimals, options.limitMethod);
            }
            if (options.minDecimals != null) {
                value = minDecimals(value, options.minDecimals);
            }
        }

        if (value.indexOf(DECIMAL_SEPARATOR) !== -1) {
            const valueParts = value.split(DECIMAL_SEPARATOR);

            if (valueParts[0] !== '-') {
                valueParts[0] =
                    parseInt(valueParts[0]) === 0
                        ? valueParts[0]
                        : numberFormat(parseInt(valueParts[0]), undefined, DECIMAL_SEPARATOR, THOUSANDS_SEPARATOR);
            }

            value = valueParts.join(DECIMAL_SEPARATOR);
        } else {
            value = numberFormat(parseInt(value), undefined, DECIMAL_SEPARATOR, THOUSANDS_SEPARATOR);
        }
    }

    return options.allowThousandsSeparator ? value : removeThousandsSeparator(value);
};

export const validateNumber = (rawValue: string | number, {range = [-Infinity, Infinity]}: any = {}) => {
    let valueAsNumber = rawValue;

    if (rawValue !== '') {
        valueAsNumber = unformatNumber(rawValue, NaN);

        const [min, max] = range;

        if (isNaN(valueAsNumber)) {
            valueAsNumber = rawValue;
        } else if (valueAsNumber < min) {
            valueAsNumber = min;
        } else if (valueAsNumber > max) {
            valueAsNumber = max;
        } else {
            valueAsNumber = rawValue;
        }
    }

    return valueAsNumber;
};

export const fixedDecimalsWithNull = (
    rawValue?: string | number | null,
    decimals: number = 1,
    method: string = NUMBER_FORMATTER_DEFAULTS.limitMethod,
) => {
    if (rawValue == null || isNaN(Number(rawValue))) {
        return null;
    }

    return fixedDecimals(rawValue, decimals, method);
};

export const getDecimals = (value: string | number): string => {
    const parsedValue: number = unformatNumber(value);

    if (Math.floor(parsedValue) === parsedValue) {
        return '';
    }

    return parsedValue.toString().split('.')[1] || '';
};

export const getNumberUnits = (value: string | number): string => {
    const parsedValue: number = Math.floor(Math.abs(unformatNumber(value)));

    return parsedValue.toString();
};
