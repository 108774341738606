import {localized} from 'src/utils/localized';

export const localesTimeLog = localized({
    actual: 'Actual',
    all: 'All Time Logs',
    approveTime: 'Approve time log',
    approveTimeAllPending: 'Approve all pending time logs',
    atLeast15Mins: 'Value must be at least 15 minutes (0.25)',
    collapse: 'Collapse',
    collapseAll: 'Collapse All',
    createdAt: 'Logged on',
    didNotWork: 'Did Not Work',
    daysNoLog: 'Missing Logs',
    download: 'Download',
    downloadAttachment: 'Download Attachment',
    employee: 'Employee',
    end: 'End Period',
    expand: 'Expand',
    expandAll: 'Expand All',
    fullDaySuccess: 'Time log was saved as full day.',
    halfDay: 'Half day',
    halfDaySuccess: 'Time log was saved as half day.',
    lessThan24: 'The hour must be less than 24.',
    list: 'Time Log List',
    logType: 'Type',
    mileage: 'Mileage',
    multipleOf15Mins: 'Value must be multiple of 15 minutes',
    noAction: 'Could not find any outstanding actions for you.',
    notApplicable: 'Not Applicable',
    overtime: 'Overtime',
    paid: 'Paid',
    project: 'Project',
    rejectLog: 'Reject time log',
    skip: 'Did Not Work',
    setFullDay: 'Set full day',
    setHalfDay: 'Set half day',
    settings: 'Settings',
    standardRate: 'Standard rate',
    start: 'Start Period',
    statusSuccess: 'Time log status was updated.',
    summary: 'Summary',
    task: 'You should save this item after the assignment is finished.',
    timeLog: 'Time Log',
    timeLogs: 'Time Logs',
    toggleExpand: 'Toggle Expand',
    unpaid: 'Unpaid',
    value: 'Worked amount',
    valueHour: 'Worked amount (hours)',
    valueUnit: 'Worked amount (unit)',
    verified: 'Verified',
    verify: 'Verify',
    verifiedSuccess: 'Time log was saved as verified.',
    worked: 'Worked',
    payFile: {
        v1: {
            column1: 'Employee Name',
            column2: 'Employee ID',
            column3: 'Rate Type',
            column4: 'Project ID',
            column5: 'Project Name',
            column6: 'Assignment ID',
            column7: 'Role Code',
            column8: 'Role Code Description',
            column9: 'Language Code',
            column10: 'Pay Period End Date',
            column11: 'Date Worked',
            column12: 'Units',
            column13: 'Overtime Units',
            column14: 'Pay Rate',
            column15: 'Bill Rate',
            column16: 'Total Pay Rate Charge',
            column17: 'Total Bill Rate Charge',
            column18: 'Fringe Amount',
            column19: 'Paid',
        },
        v2: {
            column1: 'Employee Name',
            column2: 'Employee ID',
            column3: 'Rate Type',
            column4: 'Project ID',
            column5: 'Project Name',
            column6: 'Assignment ID',
            column7: 'Role Code Description',
            column8: 'Pay Period End Date',
            column9: 'Date Worked',
            column10: 'Units',
            column11: 'Overtime Units',
            column12: 'Pay Rate',
            column13: 'Bill Rate',
            column14: 'Total Pay Rate Charge',
            column15: 'Total Bill Rate Charge',
            column16: 'Fringe Amount',
        },
    },
});
