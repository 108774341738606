export enum Role {
    SUPER_ADMIN = 'superAdmin',
    ADMIN = 'admin',
    CLIENT_ADMIN = 'clientAdmin',
    CLIENT = 'client',
    EMPLOYEE = 'employee',
}

export enum Permission {
    ROLE_PAYFILE = 'ROLE_PAYFILE',
    ROLE_REPORTS = 'ROLE_REPORTS',
    PRIMARY_TIME_APPROVER = 'PRIMARY_TIME_APPROVER',
    RESOURCE_MANAGER = 'RESOURCE_MANAGER',
    SYSTEM_CONFIG_SHOW = 'system-config:show',
    SYSTEM_CONFIG_EDIT = 'system-config:edit',
    USERS_SHOW = 'users:show',
    USERS_EDIT = 'users:edit',
    USERS_DELETE = 'users:delete',
    USERS_ACTIVATE = 'users:activate',
    EMPLOYEE_DELETE = 'employee:delete',
    EMPLOYEE_SHOW = 'employee:show',
    EMPLOYEE_EDIT = 'employee:edit',
    ORDER_SHOW = 'order:show',
    ORDER_EDIT = 'order:edit',
    ASSIGNMENT_SHOW = 'assignment:show',
    ASSIGNMENT_EDIT = 'assignment:edit',
    ASSIGNMENT_APPROVE = 'assignment:approve',
    USER_PERMISSIONS = 'user-permissions:edit',
    DASHBOARD_STATS = 'dashboard:stats',
    TIME_LOG_SHOW = 'time:show',
    TIME_LOG_EDIT = 'time:edit',
    TIME_SUPERVISOR = 'time:supervisor',
}

export const ROLE_TO_CREATE_ROLES = {
    [Role.SUPER_ADMIN]: [Role.ADMIN, Role.CLIENT_ADMIN, Role.CLIENT, Role.EMPLOYEE],
    [Role.ADMIN]: [Role.CLIENT_ADMIN, Role.CLIENT, Role.EMPLOYEE],
    [Role.CLIENT_ADMIN]: [Role.CLIENT, Role.EMPLOYEE],
    [Role.CLIENT]: [Role.EMPLOYEE],
    [Role.EMPLOYEE]: [],
};

export const rules = {
    [Role.SUPER_ADMIN]: {
        static: [],
    },
    [Role.ADMIN]: {
        static: [
            Permission.SYSTEM_CONFIG_EDIT,
            Permission.SYSTEM_CONFIG_SHOW,
            Permission.USERS_SHOW,
            Permission.USERS_EDIT,
            Permission.USERS_DELETE,
            Permission.USERS_ACTIVATE,
            Permission.EMPLOYEE_DELETE,
            Permission.EMPLOYEE_EDIT,
            Permission.EMPLOYEE_SHOW,
            Permission.ASSIGNMENT_EDIT,
            Permission.ASSIGNMENT_SHOW,
            Permission.ORDER_SHOW,
            Permission.ORDER_EDIT,
            Permission.ASSIGNMENT_APPROVE,
            Permission.USER_PERMISSIONS,
            Permission.DASHBOARD_STATS,
            Permission.TIME_LOG_SHOW,
            Permission.TIME_LOG_EDIT,
            Permission.ROLE_REPORTS,
            Permission.ROLE_PAYFILE,
            Permission.TIME_SUPERVISOR,
        ],
    },
    [Role.CLIENT_ADMIN]: {
        static: [
            Permission.DASHBOARD_STATS,
            Permission.USERS_SHOW,
            Permission.USERS_EDIT,
            Permission.EMPLOYEE_EDIT,
            Permission.EMPLOYEE_SHOW,
            Permission.ASSIGNMENT_EDIT,
            Permission.ASSIGNMENT_SHOW,
            Permission.ORDER_SHOW,
            Permission.ORDER_EDIT,
            Permission.TIME_LOG_SHOW,
        ],
    },
    [Role.CLIENT]: {
        static: [
            Permission.USERS_SHOW,
            Permission.USERS_EDIT,
            Permission.EMPLOYEE_EDIT,
            Permission.EMPLOYEE_SHOW,
            Permission.ASSIGNMENT_EDIT,
            Permission.ASSIGNMENT_SHOW,
            Permission.ORDER_SHOW,
            Permission.ORDER_EDIT,
            Permission.TIME_LOG_SHOW,
        ],
    },
    [Role.EMPLOYEE]: {
        static: [Permission.ASSIGNMENT_SHOW, Permission.ORDER_SHOW, Permission.TIME_LOG_SHOW],
    },
};
