import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import {AppBar, Box, Hidden, IconButton, Toolbar, makeStyles, SvgIcon, Theme} from '@material-ui/core';
import {Menu as MenuIcon} from 'react-feather';
import {Logo} from 'src/components/Logo';
import {Account} from 'src/layouts/InternalLayout/Account';
import {Settings} from 'src/layouts/InternalLayout/Settings';
import {Themes} from 'src/defs/constants';

const useStyles = makeStyles((theme: Theme & {name: string}) => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        ...(theme.name === Themes.LIGHT
            ? {
                  boxShadow: 'none',
                  backgroundColor: theme.palette.primary.main,
              }
            : {}),
        ...(theme.name === Themes.ONE_DARK
            ? {
                  backgroundColor: theme.palette.background.default,
              }
            : {}),
    },
    toolbar: {
        minHeight: 64,
    },
    link: {
        textDecoration: 'none',
        fontWeight: theme.typography.fontWeightMedium,
        marginLeft: theme.spacing(2),
    },
    text: {
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightMedium,
    },
}));

export const TopBar = ({className, onMobileNavOpen}: {className?: string; onMobileNavOpen: () => void}) => {
    const classes = useStyles();

    return (
        <AppBar className={clsx(classes.root, className)}>
            <Toolbar className={classes.toolbar}>
                <Hidden lgUp>
                    <IconButton color='inherit' onClick={onMobileNavOpen}>
                        <SvgIcon fontSize='small'>
                            <MenuIcon />
                        </SvgIcon>
                    </IconButton>
                </Hidden>
                <Hidden mdDown>
                    <RouterLink to='/'>
                        <Logo />
                    </RouterLink>
                </Hidden>
                <Box ml={2} flexGrow={1} />
                <Settings />
                <Box ml={2}>
                    <Account />
                </Box>
            </Toolbar>
        </AppBar>
    );
};
