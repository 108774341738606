import produce from 'immer';
import {TypeStoreAccount, TypeStoreAccountAction} from 'src/defs/types';
import {StoreActionUser, DEFAULT_USER} from 'src/defs/constants';
import {Reducer} from 'react';

const initialState = {
    user: DEFAULT_USER,
};

export const accountReducer: Reducer<TypeStoreAccount, TypeStoreAccountAction> = (state = initialState, action) => {
    switch (action.type) {
        case StoreActionUser.LOGIN_REQUEST: {
            return produce(state, (draft) => {
                draft.user = DEFAULT_USER;
            });
        }

        case StoreActionUser.LOGIN_SUCCESS: {
            const {user} = action.payload;

            return produce(state, (draft) => {
                draft.user = user;
            });
        }

        case StoreActionUser.LOGIN_FAILURE: {
            return produce(state, () => {
                // Maybe store error
            });
        }

        case StoreActionUser.SILENT_LOGOUT: {
            return produce(state, (draft) => {
                draft.user = DEFAULT_USER;
            });
        }

        case StoreActionUser.LOGOUT: {
            return produce(state, (draft) => {
                draft.user = DEFAULT_USER;
            });
        }

        case StoreActionUser.SILENT_LOGIN: {
            const {user} = action.payload;

            return produce(state, (draft) => {
                draft.user = user;
            });
        }

        case StoreActionUser.UPDATE_PROFILE: {
            const {user} = action.payload;

            return produce(state, (draft) => {
                draft.user = user;
            });
        }

        default: {
            return state;
        }
    }
};
