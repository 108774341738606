import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {SplashScreen} from 'src/components/SplashScreen';
import {setUserData, resetUserData} from 'src/store/actions/accountActions';
import authService from 'src/services/authService';
import {useHistory} from 'react-router';
import {setAxiosInterceptors} from 'src/services/networkService';
import {useSnackbar} from 'notistack';

type TypeProps = {
    children: React.ReactElement;
};

export const Auth = ({children}: TypeProps) => {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true);
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        const handleSilentLogout = async () => {
            authService.setToken(null);

            await dispatch(resetUserData());

            history.replace('/');

            setLoading(false);
        };

        const initAuth = async () => {
            authService.setAxiosAuthHeader();
            setAxiosInterceptors({
                onLogout: handleSilentLogout,
                enqueueSnackbar,
            });

            if (authService.hasAccessToken()) {
                const user = await authService.loginInWithToken();

                await dispatch(setUserData(user));
            }
            setLoading(false);
        };

        initAuth();

        // eslint-disable-next-line
    }, []);

    if (isLoading) {
        return <SplashScreen />;
    }

    return children;
};
