import {trim, snakeCase} from 'lodash';
import makeString from 'src/utils/str/makeString';
import capitalize from 'src/utils/str/capitalize';

export const dasherize = (value: string): string => {
    const libResult = trim(value)
        .replace(/([A-Z])/g, '-$1')
        .replace(/[-_\s]+/g, '-')
        .toLowerCase();

    return trim(libResult, '-');
};

export const joinTruthyStrings = (strings: Array<null | undefined | string>, separator: string = ' ') => {
    return strings.filter(Boolean).join(separator);
};

// eslint-disable-next-line max-params
export const replaceAll = (str: string, find: string, replace: string, ignorecase?: boolean) => {
    const flags = ignorecase === true ? 'gi' : 'g';
    const reg = new RegExp(find, flags);

    return makeString(str).replace(reg, replace);
};

export const join = function join(...args: any[]) {
    const workingArgs = [...args];
    const separator = workingArgs.shift();

    return workingArgs.join(makeString(separator));
};

export const slugify = (str: string): string => {
    return str
        .replace(/[^\sa-zA-Z0-9\-/,]/g, '')
        .replace(/[\s]+/g, ' ')
        .replace(/[\s/,]/g, '-');
};

// eslint-disable-next-line max-params
export const numberFormat = (number: number, dec?: number, dsep?: string, tsep?: string): string => {
    if (isNaN(number) || number == null) {
        return '';
    }

    // @ts-ignore flow complains about dec being undefined, but ~~undefined = 0
    const workingNumber = number.toFixed(~~dec); // eslint-disable-line no-bitwise
    const orderSeparator = typeof tsep == 'string' ? tsep : ',';

    const parts = workingNumber.split('.');
    const fnums = parts[0];
    const decimals = parts[1] ? (dsep || '.') + parts[1] : '';

    return fnums.replace(/(\d)(?=(?:\d{3})+$)/g, `$1${orderSeparator}`) + decimals;
};

export const titleize = (value: string): string => {
    return makeString(value)
        .toLowerCase()
        .replace(/(?:^|\s|-)\S/g, (c) => {
            return c.toUpperCase();
        });
};

export const humanize = (value: string): string => {
    return capitalize(trim(snakeCase(value).replace(/_id$/, '').replace(/_/g, ' ')));
};

export const ucFirst = (value: string): string => {
    return value.charAt(0).toUpperCase() + value.slice(1);
};
