import React from 'react';
import {Permission} from 'src/defs/rules';
import {Redirect} from 'react-router';
import {useSnackbar} from 'notistack';
import {usePermissions} from 'src/hooks/usePermissions';

export const Allowed = ({
    resource,
    fallback = null,
    children,
}: {
    resource: Permission;
    fallback?: string | null;
    children: React.ReactElement;
}) => {
    const {checkResource} = usePermissions();
    const {enqueueSnackbar} = useSnackbar();
    const isAllowed = checkResource(resource);

    if (isAllowed) {
        return children;
    }

    if (fallback !== null) {
        enqueueSnackbar('You need a higher level of permission.', {
            variant: 'error',
        });

        return <Redirect to={fallback} />;
    }

    return null;
};
