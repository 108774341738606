import {Direction} from '@material-ui/core';
import {Role} from 'src/defs/rules';

export const noop = () => {};

export enum Themes {
    LIGHT = 'LIGHT',
    ONE_DARK = 'ONE_DARK',
}

export const DEFAULT_SETTINGS = {
    direction: 'ltr' as Direction,
    responsiveFontSizes: true,
    theme: Themes.ONE_DARK,
};

export const DEFAULT_SETTINGS_CONTEXT = {
    settings: DEFAULT_SETTINGS,
    saveSettings: noop,
};

export enum Country {
    USA = 'USA',
}

export enum PayFileTemplate {
    VERSION_1 = 'v1',
    VERSION_2 = 'v2',
}

export const ROLE_TO_LABEL = {
    [Role.SUPER_ADMIN]: 'Super Admin',
    [Role.ADMIN]: 'App Admin',
    [Role.CLIENT_ADMIN]: 'Client Admin',
    [Role.CLIENT]: 'App Client',
    [Role.EMPLOYEE]: 'Employee',
};

export enum StoreActionUser {
    LOGIN_REQUEST = 'LOGIN_REQUEST',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILURE = 'LOGIN_FAILURE',
    SILENT_LOGIN = 'CHECK_USER',
    SILENT_LOGOUT = 'SILENT_LOGOUT',
    LOGOUT = 'LOGOUT',
    UPDATE_PROFILE = 'UPDATE_PROFILE',
}

export const PATHS_WITH_OWN_ERROR_HANDLER = [''];

export const LANGUAGES = [
    {name: '2-DVD Set', code: '090'},
    {name: 'Afar', code: '500'},
    {name: 'Afrikaans', code: '501'},
    {name: 'Akan Fante', code: '502'},
    {name: 'Akan Twi', code: '503'},
    {name: 'Albanian', code: '101'},
    {name: 'American Sign Language', code: '010'},
    {name: 'Amharic', code: '506'},
    {name: 'Antillean', code: '011'},
    {name: 'Antillean, British', code: '018'},
    {name: 'Antillean, French', code: '030'},
    {name: 'Apache', code: '012'},
    {name: 'Arabic', code: '102'},
    {name: 'Armenian, Eastern', code: '201'},
    {name: 'Armenian, Western', code: '104'},
    {name: 'Aymara', code: '014'},
    {name: 'Azerbeijani', code: '107'},
    {name: 'Bahaman', code: '016'},
    {name: 'Balante', code: '513'},
    {name: 'Bambara', code: '514'},
    {name: 'Banda', code: '515'},
    {name: 'Basque', code: '109'},
    {name: 'Baule (Baoule)', code: '520'},
    {name: 'Belarussian', code: '114'},
    {name: 'Bembe', code: '522'},
    {name: 'Bengali', code: '242'},
    {name: 'Bikol (Bikolano)', code: '851'},
    {name: 'Bislama', code: '852'},
    {name: 'Blind, half speed', code: '076'},
    {name: 'Blind, regular speed', code: '075'},
    {name: 'Bosnian', code: '175'},
    {name: 'Braille, English', code: '017'},
    {name: 'Bulgarian', code: '112'},
    {name: 'Burmese', code: '256'},
    {name: 'Cakchiquel', code: '019'},
    {name: 'Cambodian (KHMER)', code: '258'},
    {name: 'Cambodian, Romanized', code: '447'},
    {name: 'Catalan', code: '115'},
    {name: 'Cebuano', code: '853'},
    {name: 'Chamorro', code: '855'},
    {name: 'Chichewa  (Nyanja)', code: '535'},
    {name: 'Chinese Cantonese, Romanized', code: '263'},
    {name: 'Chinese Mandarin, Romanized', code: '264'},
    {name: 'Chinese', code: '265'},
    {name: 'Chinese, Cantonese Spoken', code: '267'},
    {name: 'Chinese, Mandarin Spoken', code: '268'},
    {name: 'Chinese, Simplified Character', code: '266'},
    {name: 'Chuukese', code: '901'},
    {name: 'Comorian', code: '539'},
    {name: 'Criolo', code: '195'},
    {name: 'Crioulo  (Portuguese Creole)', code: '541'},
    {name: 'Croation', code: '119'},
    {name: 'Czech', code: '121'},
    {name: 'Danish', code: '110'},
    {name: 'Divehi', code: '275'},
    {name: 'Dutch', code: '120'},
    {name: 'Dzongkha', code: '278'},
    {name: 'Efik', code: '553'},
    {name: 'English', code: '000'},
    {name: 'English, Australia', code: '906'},
    {name: 'English, British', code: '198'},
    {name: 'English, Philippines', code: '918'},
    {name: 'English, Singapore', code: '209'},
    {name: 'Estonian', code: '124'},
    {name: 'Ewe', code: '559'},
    {name: 'Fang', code: '561'},
    {name: 'FIJIAN (FUGI)', code: '585'},
    {name: 'Fijian', code: '858'},
    {name: 'Finnish', code: '130'},
    {name: 'Fon', code: '562'},
    {name: 'French Canadian', code: '083'},
    {name: 'French', code: '140'},
    {name: 'Fula (Fulani)', code: '563'},
    {name: 'Futa', code: '565'},
    {name: 'Georgian', code: '131'},
    {name: 'German', code: '150'},
    {name: 'Greek', code: '133'},
    {name: 'Guarani', code: '033'},
    {name: 'Haitian Creole', code: '036'},
    {name: 'Hausa', code: '584'},
    {name: 'Hawaiian', code: '860'},
    {name: 'Hebrew  (Restricted)', code: '134'},
    {name: 'Hilagaynon (Ilonggo)', code: '861'},
    {name: 'Hindi', code: '294'},
    {name: 'Hindi, Romanized', code: '298'},
    {name: 'Hmong', code: '295'},
    {name: 'Hungarian', code: '135'},
    {name: 'Iban', code: '297'},
    {name: 'Icelandic', code: '190'},
    {name: 'Igbo (Ibo)', code: '593'},
    {name: 'Ilokano', code: '864'},
    {name: 'Indonesian', code: '299'},
    {name: 'Inupiaq', code: '029'},
    {name: 'Italian', code: '160'},
    {name: 'Jamaican', code: '042'},
    {name: 'Japanese', code: '300'},
    {name: 'Japanese, Romanized Script', code: '301'},
    {name: 'Javanese', code: '303'},
    {name: 'Kamba', code: '604'},
    {name: 'Kannada', code: '308'},
    {name: 'Kazakh', code: '145'},
    {name: 'Kekchi', code: '043'},
    {name: 'Kikongo  (Kongo)', code: '613'},
    {name: 'Kiribati', code: '859'},
    {name: 'Kirundi (Rundi)', code: '617'},
    {name: 'Kisii  (Gusii)', code: '618'},
    {name: 'Korean Sign Language', code: '449'},
    {name: 'Korean', code: '320'},
    {name: 'Korean, English annotated', code: '443'},
    {name: 'Kosraean', code: '868'},
    {name: 'Kpelle', code: '624'},
    {name: 'Krio', code: '625'},
    {name: 'Kuna', code: '044'},
    {name: 'Kyrgyz', code: '146'},
    {name: 'Laotian', code: '331'},
    {name: 'Latvian', code: '153'},
    {name: 'Lingala', code: '639'},
    {name: 'Lithuanian', code: '156'},
    {name: 'Luganda', code: '644'},
    {name: 'Macedonian', code: '158'},
    {name: 'Makua', code: '653'},
    {name: 'Malagasy', code: '654'},
    {name: 'Malay', code: '348'},
    {name: 'Malayalam', code: '349'},
    {name: 'Malinke (Mandinka)', code: '655'},
    {name: 'Maltese', code: '159'},
    {name: 'Mam', code: '046'},
    {name: 'Maori', code: '871'},
    {name: 'Marathi', code: '355'},
    {name: 'Marshallese', code: '874'},
    {name: 'Mauritian', code: '669'},
    {name: 'Maya', code: '048'},
    {name: 'Mbundu', code: '673'},
    {name: 'Mende', code: '676'},
    {name: 'Moldavian', code: '208'},
    {name: 'Mongolian', code: '363'},
    {name: 'Montenegrin', code: '179'},
    {name: 'Moore (More)', code: '681'},
    {name: 'Motu', code: '876'},
    {name: 'Navajo', code: '054'},
    {name: 'Ndebele  (Sindebele)', code: '687'},
    {name: 'Neomelanesian', code: '878'},
    {name: 'Nepali', code: '372'},
    {name: 'Niuean', code: '880'},
    {name: 'Nivacle (Chulupi)', code: '025'},
    {name: 'North Sotho', code: '738'},
    {name: 'Norwegian', code: '170'},
    {name: 'Palauan', code: '881'},
    {name: 'Pampango', code: '882'},
    {name: 'Pangasinan', code: '883'},
    {name: 'Papiamento', code: '058'},
    {name: 'Pashto', code: '381'},
    {name: 'Persian  (Farsi)', code: '382'},
    {name: 'Pocomchi', code: '077'},
    {name: 'Pohnpeian', code: '885'},
    {name: 'Polish', code: '166'},
    {name: 'Portuguese', code: '059'},
    {name: 'Portuguese, European', code: '206'},
    {name: 'Punjabi', code: '386'},
    {name: 'Quechua, Bolivia', code: '060'},
    {name: 'Quechua, Peru', code: '061'},
    {name: 'Quiche', code: '062'},
    {name: 'Quichua, Ecuador', code: '064'},
    {name: 'Rabonal Achi', code: '078'},
    {name: 'Rarotongan', code: '886'},
    {name: 'Romanian', code: '171'},
    {name: 'Rotuman', code: '887'},
    {name: 'Ruanda (Kinyarwanda)', code: '716'},
    {name: 'Russian (Russian Label', code: '941'},
    {name: 'Russian', code: '173'},
    {name: 'Samoan', code: '890'},
    {name: 'Serbian', code: '202'},
    {name: 'Setswana', code: '763'},
    {name: 'Seychellese', code: '725'},
    {name: 'Shona', code: '731'},
    {name: 'Sinhala', code: '408'},
    {name: 'Slovak', code: '176'},
    {name: 'Slovenian', code: '177'},
    {name: 'Solomonese', code: '891'},
    {name: 'Somali', code: '734'},
    {name: 'South Sotho', code: '739'},
    {name: 'Spanish Castillian', code: '178'},
    {name: 'Spanish', code: '002'},
    {name: 'Spanish, Brail', code: '004'},
    {name: 'Sranan', code: '066'},
    {name: 'Swahili Shaba', code: '744'},
    {name: 'Swahili', code: '743'},
    {name: 'Swazi (Siswati)', code: '745'},
    {name: 'Swedish', code: '180'},
    {name: 'Tagalog (Filipino)', code: '893'},
    {name: 'Tahitian', code: '895'},
    {name: 'Tajiki', code: '181'},
    {name: 'Tamil', code: '418'},
    {name: 'Telugu', code: '421'},
    {name: 'Thai', code: '425'},
    {name: 'Thai, Romanized', code: '426'},
    {name: 'Tibetan', code: '427'},
    {name: 'Tongan', code: '900'},
    {name: 'Tsihiluba  (Luba-Kasai)', code: '760'},
    {name: 'Turkish', code: '186'},
    {name: 'Turkmen', code: '187'},
    {name: 'Tuvaluan', code: '902'},
    {name: 'Tzotzil', code: '069'},
    {name: 'Ukrainian Label', code: '943'},
    {name: 'Ukrainian', code: '192'},
    {name: 'Urdu', code: '434'},
    {name: 'Uzbek', code: '193'},
    {name: 'Vietnamese', code: '435'},
    {name: 'Visually Impaired, Extra Large', code: '081'},
    {name: 'Waray', code: '904'},
    {name: 'Welsh', code: '194'},
    {name: 'Western Caribbean Creole', code: '072'},
    {name: 'Wolof', code: '773'},
    {name: 'Xhosa', code: '774'},
    {name: 'Yapese', code: '905'},
    {name: 'Yoruba', code: '779'},
    {name: 'Yupik', code: '082'},
    {name: 'Zulu', code: '783'},
];

export const DEFAULT_LANGUAGE_CODE = '000';

export const DEFAULT_USER = {
    id: '',
    name: '',
    username: '',
    email: '',
    password: '',
    disabled: true,
    parent: '',
    parentManager: '',
    parentOrganization: '',
    role: Role.EMPLOYEE,
    permissions: [],
    phone: '',
    country: Country.USA,
    state: '',
    city: '',
    canHire: false,
    acknowledgement: '',
    hasWorkfront: false,
    languageCode: DEFAULT_LANGUAGE_CODE,
    organization: '',
    markup: 0,
    weekStart: 'Sunday',
    isTempleRecNeeded: false,
    payFile: PayFileTemplate.VERSION_1,
};

export const COUNTRIES = ['USA'];
export enum State {
    '-' = '',
    AL = 'Alabama',
    AK = 'Alaska',
    AS = 'American Samoa',
    AZ = 'Arizona',
    AR = 'Arkansas',
    CA = 'California',
    CO = 'Colorado',
    CT = 'Connecticut',
    DE = 'Delaware',
    DC = 'District Of Columbia',
    FM = 'Federated States Of Micronesia',
    FL = 'Florida',
    GA = 'Georgia',
    GU = 'Guam',
    HI = 'Hawaii',
    ID = 'Idaho',
    IL = 'Illinois',
    IN = 'Indiana',
    IA = 'Iowa',
    KS = 'Kansas',
    KY = 'Kentucky',
    LA = 'Louisiana',
    ME = 'Maine',
    MH = 'Marshall Islands',
    MD = 'Maryland',
    MA = 'Massachusetts',
    MI = 'Michigan',
    MN = 'Minnesota',
    MS = 'Mississippi',
    MO = 'Missouri',
    MT = 'Montana',
    NE = 'Nebraska',
    NV = 'Nevada',
    NH = 'New Hampshire',
    NJ = 'New Jersey',
    NM = 'New Mexico',
    NY = 'New York',
    NC = 'North Carolina',
    ND = 'North Dakota',
    MP = 'Northern Mariana Islands',
    OH = 'Ohio',
    OK = 'Oklahoma',
    OR = 'Oregon',
    PW = 'Palau',
    PA = 'Pennsylvania',
    PR = 'Puerto Rico',
    RI = 'Rhode Island',
    SC = 'South Carolina',
    SD = 'South Dakota',
    TN = 'Tennessee',
    TX = 'Texas',
    UT = 'Utah',
    VT = 'Vermont',
    VI = 'Virgin Islands',
    VA = 'Virginia',
    WA = 'Washington',
    WV = 'West Virginia',
    WI = 'Wisconsin',
    WY = 'Wyoming',
}

export enum ActionName {
    CREATE = 'create',
    EDIT = 'edit',
    SHOW = 'details',
    DELETE = 'delete',
}

export const TAB_PANEL_SEARCH_NAME = 'tab';
export const URL_PARAM_SYNCED = 'showSynced';
export const URL_PARAM_STATUS = 'status';

export enum RecordStatus {
    PENDING = 'pending',
    APPROVED = 'approved',
    REJECTED = 'rejected',
}

const {REACT_APP_HIRING_LINK = '', REACT_APP_PORTAL_LINK = ''} = process.env;

export const HIRING_LINK = REACT_APP_HIRING_LINK;
export const PORTAL_LINK = REACT_APP_PORTAL_LINK;

export const STATUS_ALL_RECORD = 'all';
export const PREFETCH_VALUE = 150;
